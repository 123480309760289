const createValidation = (fn, errorMsg) => (data) => {
  const result = fn(data);

  return {
    cata: (branch) => (result ? branch.right(result) : branch.left(errorMsg)),
  };
};

const validateFormValues = (values) =>
  Object.keys(values).map((key) => ({
    [key]: values[key].cata({
      left: (e) => e,
      right: (a) => a,
    }),
  }));

export { validateFormValues, createValidation };
