import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import SplitPane from 'src/components/SplitPane/SplitPane'
import { Header } from 'src/components/Header'
import { Sidebar } from 'src/components/Sidebar'
import styles from './Layout.module.scss'
import { Context } from '../../context/GlobalContext'
import { enablePlaygroundForPath } from '../Playground/config'
import { IframeOverlay } from './IframeOverlay'
import { FloatButton } from './FloatButton'
import { useDraggable } from './useDraggable'

const Layout = React.memo(({ children }) => {
  const [state, dispatch] = useContext(Context)
  const iframeRef = useRef(null)
  const [dragging, setDragging] = useState(false)
  const location = useLocation()
  const { t } = useTranslation('translation',{ keyPrefix: 'layout'});

  // this function will be called by playground iframe
  // TODO: maybe we can use postMessage to do this
  window.closePlayground = () => {
    dispatch({ type: 'TOGGLE_PLAYGROUND', payload: false })
  }

  const onDragStart = () => {
    setDragging(true)
  }
  const onDragEnd = () => {
    setDragging(false)
  }

  const handleButtonDrag = useCallback(
    ({ y }) => {
      let limitY = y;
      if (y > 30){
        limitY = 30;
      } else if(y < - (window.innerHeight - 180)){
        limitY = - (window.innerHeight - 180);
      }
      return ({
        x: 0,
        y: limitY
      })},
    []
  );
  const [floatButtonRef, pressed, dragged] = useDraggable({onDrag: handleButtonDrag});

  const togglePlayground = () => {
    // when dragged, do not toggle playground
    if(dragged) return;
    dispatch({type: "TOGGLE_PLAYGROUND", payload: !state.playgroundOpen });
  }

  useEffect(() => {
    setDragging(pressed)
  }, [pressed])

  useEffect(() => {
    dispatch({ type: 'SET_REF_PLAYGROUND_IFRAME', payload: iframeRef })
  }, [iframeRef])

  return (
    <>
      <Header/>
      <div className={styles.main}>
        <Sidebar/>
        <div className={styles.content}>
          <SplitPane percentage secondaryMinSize={20} onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <>
              {dragging && <IframeOverlay/>}
              {
                (state.screenSize === 'desktop' && enablePlaygroundForPath(location.pathname)) &&
                <div ref={floatButtonRef} className={styles.floatButton}>
                  <FloatButton onClick={togglePlayground}/>
                </div>
              }
              {children}
            </>
            {
              (state.playgroundOpen && enablePlaygroundForPath(location.pathname)) &&
              <>
                {/* fix iframe pane splitter dragging */}
                {dragging && <IframeOverlay/>}
                <iframe
                  ref={iframeRef}
                  frameBorder="0"
                  className={styles.iframe}
                  src="/playground"
                  title={t("Iframe")}
                  allowFullScreen
                />
              </>
            }
          </SplitPane>
        </div>
      </div>
    </>
  )
})

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { Layout }
