import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Tabs } from 'antd'
import { CaretRightOutlined, ReloadOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import { tabOptions } from './tabOptions'
import { Editor } from './Editor'
import './ScriptLab.scss'

const Operations = () => {
  const { t } = useTranslation('translation',{ keyPrefix: 'Playground'});

  const history = useHistory()

  const refresh = () => {
    window.location.reload()
  }

  const run = () => {
    history.push('/playground/runner')
  }

  return <div className="actions">
    {
      false && <Button type="text" icon={<ReloadOutlined/>} onClick={refresh}>{t("Refresh")}</Button>
    }
    <Button type="primary" icon={<CaretRightOutlined/>} onClick={run}>{t("Run")}</Button>
  </div>
}

const items = tabOptions.map(({ barDisplayName, name }) => {
  return {
    label: barDisplayName,
    key: name,
    children: null,
  }
})

const TopTab = ({ onTabChange }) => {
  return <Tabs defaultActiveKey="javascript" onChange={onTabChange} tabBarExtraContent={<Operations/>} items={items}/>
}

TopTab.propTypes = {
  onTabChange: PropTypes.func.isRequired,
}

const FooterBar = () => {
  return null
}

const defaultActiveTabKey = 'javascript'
const EditorContainer = () => {
  const [tabActive, setTabActive] = useState(defaultActiveTabKey)
  return <>
    <TopTab onTabChange={setTabActive}/>
    <Editor tabActive={tabActive}/>
    <FooterBar/>
  </>
}

export const ScriptLab = () => {
  return <div className="script-lab">
    <EditorContainer/>
  </div>
}

