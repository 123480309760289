import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useLocation } from "react-router-dom";

import { Spinner } from "src/components/Spinner";
import { Context } from "src/context/GlobalContext";
import { validateFormValues } from "src/utils/validation/validateForm";
import { checkValidationUrl } from "src/utils/validation/utils";
import warning from "src/assets/svg/warning.svg";
import { Button } from "src/components/Button";
import styles from "./Iframe.module.scss";

const Iframe = React.memo(({ src }) => {
  const iframeRef = useRef(null);
  const location = useLocation();
  const [error, setError] = useState({});
  const [url, setUrl] = useState("");
  const [urlCollaboration, setUrlCollaboration] = useState("");
  const [state, dispatch] = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    setUrl(event.target.value.trim());
  };
  const formValues = {
    url: checkValidationUrl(url),
  };

  const errors = validateFormValues(formValues);

  const checkValidation = () =>
    Object.keys(formValues).every((item, index) => {
      if (typeof errors[index][item] === "string") {
        setError({
          [item]: errors[index][item],
        });
        return false;
      }
      setError({
        [item]: "",
      });
      return errors[index][item] === true;
    });

  const handleCollaboration = (event) => {
    event.preventDefault();

    if (checkValidation() || process.env.NODE_ENV === 'development') {
      setUrlCollaboration(url);
    }
  };

  useEffect(() => {
    dispatch({ type: "SET_REF_IFRAME", payload: iframeRef });
  }, [iframeRef]);

  useEffect(() => {
    dispatch({ type: "SET_IS_LOADING", payload: false });
  }, []);

  function reloadPlayground(){
    if(state.playgroundOpen){
      // should use iframe load state to check if it's loaded
      setTimeout(function (){
        state.refPlaygroundIframe.current?.contentWindow.location.reload();
      }, 1000);
    }
  }

  const generateIframeURL = () => src.replace(/(#[\\/\w]+$)/, `?screen-size=${state.screenSize}$1`)
  const [iframeURL, setURL] = useState(generateIframeURL());
  useEffect(() => {
    const $iframeURL = generateIframeURL();
    setURL($iframeURL);
  }, [state.screenSize])
  return (
    <div className={styles.container}>
      <iframe
        ref={iframeRef}
        onLoad={() => {
          setIsLoading(true);
          reloadPlayground();
          dispatch({ type: "SET_IS_LOADING", payload: true });
        }}
        className={cx(styles.iframe, {
          [styles.mobile]:
            state.screenSize === "mobile" &&
            location.pathname !== "/collaboration",
          [styles.table]:
            state.screenSize === "tablet" &&
            location.pathname !== "/collaboration",
        })}
        frameBorder="0"
        src={iframeURL}
        title="Iframe"
        allowFullScreen
      />
      {location.pathname === "/collaboration" && state.showCollaboration && (
        <div className={styles.sectionCollaboration}>
          {!urlCollaboration && <div className={styles.control}>
            <div style={{ position: "relative" }}>
              <p className={styles.label}>Enter Collaboration url</p>
              <div
                className={styles.close}
                onClick={() => {
                  if (setUrlCollaboration) {
                    dispatch({
                      type: "TOGGLE_SHOW_COLLABORATION",
                      payload: false,
                    });
                    setUrl("");
                    setUrlCollaboration("");
                  }
                }}
              />
            </div>
            <div>
              <form className={styles.form} onSubmit={handleCollaboration}>
                <input
                  placeholder="Enter URL"
                  className={styles.input}
                  value={url}
                  onChange={handleChange}
                />
                <div>
                  <Button title="Go" type="submit" variant="containedOrange" />
                </div>
              </form>
            </div>
          </div>}
          {urlCollaboration ? (
            <iframe
              onLoad={() => setIsLoading(true)}
              frameBorder="0"
              className={styles.iframeCollaboration}
              src={urlCollaboration}
              title="Iframe collaboration"
            />
          ) : (
            <div className={styles.sectionError}>
              <div className={styles.box}>
                {error.url ? (
                  <div className={styles.boxError}>
                    <div className={styles.boxImg}>
                      <img src={warning} alt="warning" />
                    </div>
                    <p className={styles.placeholder}>{error.url}</p>
                  </div>
                ) : (
                  <p className={styles.placeholder}>
                    Paste the collaboration URL above to preview collaboration
                    here
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {!isLoading && (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      )}
    </div>
  );
});

Iframe.propTypes = {
  src: PropTypes.string.isRequired,
};

export { Iframe };
