export function computeAIChatBaseURL(){
  const {hostname} = window.location;
  if(hostname.indexOf("-stg") > 0){
    return "https://cloudapi-stg.foxitcloud.com"
  }

  if(hostname.indexOf("foxit.com") > 0){
    return ""
  }

  return "https://cloudapis-azk8s.foxitcloud.com"
}
