import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import playgroundSVG from 'src/assets/svg/playground.svg'
import style from './FloatButton.module.scss'

export const FloatButton = ({onClick}) => {
  return <Tooltip title="Toggle Playground">
    <button  onClick={onClick} className={style.floatButton}>
       <img src={playgroundSVG} alt="" draggable="false" />
    </button>
  </Tooltip>
}

FloatButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

