import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { Context } from "src/context/GlobalContext";
import styles from "./SelectMenu.module.scss";

const SelectMenu = React.memo(({ setIsOpenBurgerMenu }) => {
  const { t } = useTranslation('translation',{ keyPrefix: 'sidebar'});
  const [state, dispatch] = useContext(Context);
  useEffect(() => {
    if(state.screenSize === 'tablet') {
      if(state.orientation === 'landscape') {
        if(state.isOpenSidebar) {
          return;
        }
        dispatch({ type: "TOGGLE_SIDEBAR", payload: true });
      }
    }
  }, [state.orientation]);
  return (
    <div
      onClick={() => {
        setIsOpenBurgerMenu(false);
        dispatch({ type: "TOGGLE_SIDEBAR", payload: !state.isOpenSidebar });
      }}
      className={cx(styles.container, {
        [styles.containerActive]: state.isOpenSidebar,
      })}
    >
      <p className={styles.title}>{t(state.menuTitle)}</p>
    </div>
  );
});

SelectMenu.propTypes = {
  setIsOpenBurgerMenu: PropTypes.func.isRequired,
};

export { SelectMenu };
