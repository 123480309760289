export const envConfig = {
  CN: {
    test: "https://webviewer-demo-devcn.foxitcloud.com",
    stg: "https://webviewer-demo-stgcn.foxitcloud.com",
    pro: "https://webviewer-demo.foxitsoftware.cn",
  },
  international: {
    test: "https://webviewer-demo-azk8s.foxitcloud.com",
    stg: "https://webviewer-demo-stg.foxitcloud.com",
    pro: "https://webviewer-demo.foxit.com",
  },
};

