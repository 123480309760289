import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { CloseOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { useTranslation } from "react-i18next";
import { computeAIChatBaseURL } from 'src/utils/computeAIBaseURL'
import {isCNHost} from "src/utils/isCNHost";
import robotSVG from 'src/assets/svg/robot.svg'
import { ScriptLab } from './ScriptLab'
import { Runner } from './Runner'
import style from './Playground.module.scss'

function closePlayground(){
  if(window.parent?.closePlayground){
    window.parent?.closePlayground()
  }
}

export const Playground = () => {
  const { t } = useTranslation('translation',{ keyPrefix: 'Playground'});

  return <div className={style.playgroundContainer}>
    <div className={style.header}>
      <h3 className={style.title}>{t("Playground")}</h3>
      <div className={style.actions}>
        <a className={style.aiButton}
           style={{display:isCNHost?'none':'inline-block'}}
           target="_blank"
           rel="noopener noreferrer"
           href={`${computeAIChatBaseURL()}/ai-assistant/?ref=websdk_playground`}>
          <img src={robotSVG} alt=""/>
          {t("Coding With AI")}
        </a>
        <Button onClick={closePlayground} type="text" shape="circle" size="small" icon={<CloseOutlined/>}/>
      </div>
    </div>
    <Switch>
      <Route path="/playground/runner" exact>
        <Runner/>
      </Route>
      <Route path="/playground">
        <ScriptLab/>
      </Route>
    </Switch>
  </div>

}
