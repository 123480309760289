const javascript = `
// You can customize the code here.
// Available global variables: pdfui, PDFViewCtrl, and UIExtension.
// The following code example adds an annotation to the first page. Click "Run" to execute it.

const annotJSON = [{
  'type': 'square',
  'color': '#ff0000',
  'flags': 'print',
  'name': 'b9f5a857-ed05-42a8-8866-4367bb823b34',
  'page': 0,
  'rect': '57.98138427734375,285.90771484375,154.19668579101562,340.7594299316406',
  'contents': '',
  'width': 2,
  'customEntries': {},
  'objectNumber': 80,
  'date': 'D:20230605153835+08\\'00\\'',
  'style': 'solid',
  'opacity': 1,
  'creationdate': 'D:20230605153836+08\\'00\\'',
  'subject': 'Rectangle',
  'title': 'Foxit Web',
  'popup': {
    'flags': 'print,',
    'name': 'be40e11a-5732-44fd-8da0-3d3adc762a54',
    'page': 0,
    'open': 'no',
    'rect': '57.98138427734375,285.90771484375,154.19668579101562,340.7594299316406',
    'type': 'popup'
  },
  'fringe': '0,0,0,0'
}]

function addAnnot(){
  pdfui.getCurrentPDFDoc().then(pdfDoc => {
    pdfDoc.importAnnotsFromJSON(annotJSON).then(() => {
      console.log("imported")
    })
  })
}
`

const html = '<button class="add-annot" onclick="addAnnot()">Add Annot</button>'

const css = `.add-annot {
    color: #fff;
    background-color: #19be6b;
}
.add-annot:hover {
    color: #fff;
    background-color: #47cb89;
    border-color: #47cb89;
}
.add-annot:active {
    color: #fff;
    background-color: #18b566;
    border-color: #18b566;
}
.add-annot:focus {
    box-shadow: 0 0 0 2px rgba(25, 190, 107, .2);
}
`
const libraries = `// All Libraries (can be. css. js) must be found by unpkg.com. Please test it by yourself

// For example:

// normalize.css
// view-design@4.2.0 /dist/styles/iview.css
// jquery@3.1.1
// https://unpkg.com/dayjs @1.8.21/dayjs.min.js

normalize.css
view-design@4.2.0/dist/styles/iview.css
jquery@3.1.1
https://unpkg.com/dayjs@1.8.21/dayjs.min.js
`

const runtime = `
  window._exampleFrameWindow = window.parent.exampleOuterFrame.contentWindow.frames[0].window
  window.pdfui = _exampleFrameWindow.pdfui 
  window.PDFViewCtrl = _exampleFrameWindow.PDFViewCtrl
  window.UIExtension = _exampleFrameWindow.UIExtension
`
export default {
  javascript,
  html,
  css,
  libraries,
  runtime
}
