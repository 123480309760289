import React, { useCallback, useEffect, useRef } from 'react'
import MonocaEditor, { loader } from '@monaco-editor/react'
import PropTypes from 'prop-types'
import { v4 as uuidV4 } from 'uuid'
import { CODE_ID_PREFIX, RUNNER_CODE_ID_KEY } from '../config'
import defaultCode from '../default-code'
import { createDefaultCodeTree, getStorageCodeTreeByCodeIdKey } from '../utils'

loader.config({
  paths: {
    vs: 'https://www.unpkg.com/monaco-editor@0.38.0/min/vs',
  },
})

class CodeStorage {

  getCode (tabActive) {
    this.runnerCodeId = localStorage.getItem(RUNNER_CODE_ID_KEY)
    // the first time
    if (!this.runnerCodeId) {
      this.runnerCodeId = uuidV4()
      localStorage.setItem(RUNNER_CODE_ID_KEY, this.runnerCodeId)
      this.saveCode()
      return this.getDefaultCode(tabActive)
    }

    const codeIdKey = `${CODE_ID_PREFIX}#${this.runnerCodeId}`
    const localStorageCodeTree = getStorageCodeTreeByCodeIdKey(codeIdKey)
    if (localStorageCodeTree === null) {
      this.saveCode()
      return this.getDefaultCode(tabActive)
    }
    const { files } = localStorageCodeTree
    const changeCodeObj = files.find(item => item.name === tabActive)
    if (changeCodeObj === undefined) {
      return this.getDefaultCode(tabActive)
    }
    const code = changeCodeObj.content
    return code || ''
  }

  getDefaultCode = (tabActive) => {
    return `${defaultCode[tabActive]}`
  }

  saveCode = (code, tabActive = 'javascript') => {
    const codeIdKey = `${CODE_ID_PREFIX}#${this.runnerCodeId}`
    const localStorageCodeTree = getStorageCodeTreeByCodeIdKey(codeIdKey)
    if (localStorageCodeTree === null) {
      return this.saveDefaultCode2Storage(codeIdKey)
    }

    const { files } = localStorageCodeTree
    const changeCodeObj = files.find(item => item.name === tabActive)
    if (changeCodeObj === undefined) {
      return null
    }
    const newDate = +new Date()
    changeCodeObj.content = code
    changeCodeObj.dateLastModified = newDate
    localStorageCodeTree.dateLastModified = newDate
    localStorage.setItem(codeIdKey, JSON.stringify(localStorageCodeTree))
    return null
  }

  saveDefaultCode2Storage (codeIdKey) {
    const createdCodeTree = createDefaultCodeTree({
      id: this.runnerCodeId
    })
    localStorage.setItem(codeIdKey, JSON.stringify(createdCodeTree))
  }
}

const codeStorage = new CodeStorage()

export const Editor = ({ tabActive }) => {

  const editorRef = useRef(null)
  const monacoRef = useRef(null)

  const handleEditorDidMount = useCallback((editor, monaco) => {
    editorRef.current = editor
    monacoRef.current = monaco
    editor.setValue(codeStorage.getCode(tabActive))

    // monaco.languages.registerCompletionItemProvider('javascript', {
    //   provideCompletionItems (model, position) {
    //     const { lineNumber, column } = position
    //     const content = model.getLineContent(lineNumber)
    //     const sym = content[column - 2]
    //     const word = model.getWordUntilPosition({
    //       ...position,
    //       column: column - 1 // . 往前一位获取单词
    //     })
    //     console.log(sym, word)
    //     const suggestions = []
    //     return {
    //       suggestions
    //     }
    //   },
    //   triggerCharacters: ['pdfui']
    // })

  }, [tabActive])

  useEffect(() => {
    // const language = tabActive
    if (monacoRef.current && editorRef.current) {
      const editor = editorRef.current
      // monacoRef.current?.editor.setModelLanguage(editor.getModel(), language)
      editor.setValue(codeStorage.getCode(tabActive))
    }
  }, [tabActive])

  const onChange = useCallback((value) => {
    if (value === codeStorage.getDefaultCode(tabActive)) {
      return
    }
    codeStorage.saveCode(value, tabActive)
  }, [tabActive])

  return <MonocaEditor
    height="calc(100vh - 120px)"
    defaultLanguage="javascript"
    language={tabActive}
    onMount={handleEditorDidMount}
    onChange={onChange}
    options={{
      minimap: {
        enabled: false
      },
      folding: true,
      foldingStrategy: 'indentation',
      automaticLayout: true,
      overviewRulerBorder: false,
      scrollBeyondLastLine: true,
      autoClosingBrackets: 'always',
      autoClosingQuotes: 'always',
    }}
  />
}

Editor.propTypes = {
  tabActive: PropTypes.string.isRequired,
}
