const CODE_ID_PREFIX = 'code'
const RUNNER_CODE_ID_KEY = 'runnerCodeId'

export const demosToDisablePlayground = [
  '/collaboration',
  '/conversion',
  '/overlay-comparison',
  '/multi-instance'
]

export function enablePlaygroundForPath (pathname) {
  return demosToDisablePlayground.indexOf(pathname) === -1
}

export {
  CODE_ID_PREFIX,
  RUNNER_CODE_ID_KEY
}
