import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { isCNHost } from 'src/utils/isCNHost'
import enUS from './locales/en-US.json'
import zhCN from './locales/zh-CN.json'
import zhTW from './locales/zh-TW.json'
import { LANGUAGE_TYPE } from './const'

const getDefaultLang = () => {
    if (isCNHost) {
        return LANGUAGE_TYPE['zh-CN']
    }
    if (localStorage.getItem('lang')) {
        return localStorage.getItem('lang');
    }
    if (navigator.language === 'en') {
        return LANGUAGE_TYPE['en-US'];
    }
    return navigator.language;
}

const resources = {
    'en-US': {
        translation: {...enUS}
    },
    'zh-CN': {
        translation: {...zhCN}
    },
    'zh-TW': {
        translation: {...zhTW}
    }
}

i18n.use(initReactI18next)
    .init({
        resources,
        lng: getDefaultLang(),
        fallbackLng: 'en-US',
        interpolation: {
            escapeValue: false
        },
    })