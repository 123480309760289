export const tabOptions = [
    {
        name: 'javascript',
        displayName: 'Script',
        language: 'javascript',
        barDisplayName: 'JavaScript'
    },
    {
        name: 'html',
        displayName: 'HTML',
        language: 'html',
        barDisplayName: 'HTML'
    },
    {
        name: 'css',
        displayName: 'CSS',
        language: 'css',
        barDisplayName: 'CSS'
    },
    {
        name: 'libraries',
        displayName: 'Libraries',
        language: 'libraries',
        barDisplayName: ''
    }
];