import { v4 as uuidV4 } from 'uuid'
import defaultCode from './default-code'
// import less from 'less';

/**
 * @description: createDefaultCodeTree 创建默认代码树
 * @param {Object}
 *  - id 外层创建默认代码片段时会自动创建当前代码的id
 *  - name 当前代码片段的name (保留)
 *  - host 对哪些使用环境生效 (保留)
 *  - description 当前代码片段的描述 (保留)
 * @return {Object} 返回创建成功的
 */
const createDefaultCodeTree = ({
  id = '00000000-0000-0000-0000-000000000000',
  name = 'Blank snippet',
  host = 'WEB',
  description = 'Create a new snippet from a blank template.'
}) => {
  const currDate = +new Date()
  const json = {
    id,
    name,
    host,
    description,
    options: {},
    files: [
      {
        id: uuidV4(),
        name: 'javascript',
        content: defaultCode.javascript,
        language: 'javascript',
        dateCreated: currDate,
        dateLastModified: currDate,
        dateLastOpened: currDate
      }, {
        id: uuidV4(),
        name: 'html',
        content: defaultCode.html,
        language: 'html',
        dateCreated: currDate,
        dateLastModified: currDate,
        dateLastOpened: currDate
      }, {
        id: uuidV4(),
        name: 'css',
        content: defaultCode.css,
        language: 'css',
        dateCreated: currDate,
        dateLastModified: currDate,
        dateLastOpened: currDate
      }, {
        id: uuidV4(),
        name: 'libraries',
        content: defaultCode.libraries,
        language: 'libraries',
        dateCreated: currDate,
        dateLastModified: currDate,
        dateLastOpened: currDate
      }
    ],
    dateCreated: currDate,
    dateLastModified: currDate,
    dateLastOpened: currDate
  }
  return json
}

/**
 * @description: getStorageCodeTreeByCodeIdKey 根据codeIdKey获取储存在localStorage里的代码块对象
 * @param {String} codeIdKey
 * @return {String} 返回获取到的代码块对象，不存在则返回null
 *  注意：不止包含代码块，还包含其他的参数
 */
const getStorageCodeTreeByCodeIdKey = (codeIdKey) => {
  const codeObj = localStorage.getItem(codeIdKey)
  return codeObj === null ? codeObj : JSON.parse(codeObj)
}

/**
 * @description: appendChild runner append to 指定dom节点
 * @param {Node | String | Number | *} child 要添加的node
 * @param {Node} appendTo 指定appendTo 的 dom节点
 * @return {*}
 */
const appendChild = (child, appendTo = 'body') => {
  if (!child) {
    return
  }
  let transfer = appendTo
  if (appendTo === 'body') {
    transfer = document.body
  }
  let node = child
  if (child.nodeType !== 1) {
    node = document.createRange().createContextualFragment(child)
  }
  transfer.appendChild(node)
}

/**
 * @description: findCodeOfName 根据name在localStorageCodeTree的files中查找对应code
 * @param {String} name code的唯一name
 * @param {Array} files localStorageCodeTree下的files
 * @return {Object} 返回找打的code对象
 */
const findCodeOfName = (name, files = []) => {
  if (!name) {
    return null
  }
  return files.find(item => item.name === name)
}

/**
 * @description: addPrefixOfLess 添加特定前缀且使用less编译
 * @param {String} css 用户自定义编写的css
 * @return {String} 返回编译后的css
 */
const addPrefixOfLess = (css = '') => {
  // const lessStr = `.iframe-html {
  //       ${css}
  //   }`
  // let output = `.iframe-html {}`
  // less.render(lessStr, (err, res) => {
  //     if (err) {
  //         throw new Error(err);
  //     } else {
  //         output = res.css;
  //     }
  // });
  // return output
  return css
}

/**
 * @description: processLibraries 更加libraries
 * @param {String} libraries
 * @return {Objetc} 返回包含 linkReferences [], scriptReferences[] 对象
 */
const processLibraries = (libraries = '') => {
  // link css eg: unpkg.com/view-design@4.2.0/dist/styles/iview.css | view-design@4.2.0/dist/styles/iview.css
  const linkReferences = []
  // script lib eg: jquery@3.1.1 | https://unpkg.com/dayjs@1.8.21/dayjs.min.js
  const scriptReferences = []

  function processLibrary (text = '') {
    if (text === null || text === undefined) {
      return null
    }
    // text = text.trim()
    if (text === '' || text.startsWith('#') || text.startsWith('//')) {
      return null
    }
    const isDts = /^@types/.test(text) || /^dt~/.test(text) || /\.d\.ts$/i.test(text)
    if (isDts) {
      return null
    }
    const resolvedUrlPath = /^https?:\/\/|^ftp? :\/\//i.test(text)
      ? text
      : `https://unpkg.com/${text}`
    if (/\.css$/i.test(resolvedUrlPath)) {
      return linkReferences.push(resolvedUrlPath)
    }
    return scriptReferences.push(resolvedUrlPath)
  }

  if (libraries) {
    libraries.split('\n').forEach(processLibrary)
  }
  return {
    linkReferences,
    scriptReferences
  }
}

const openWindow = (url, w = 950, h = 450) => {
  const left = Math.round((window.screen.availWidth - w) / 2)
  const top = Math.round((window.screen.availHeight - 100 - h) / 2)
  window.open(url, '', `height= ${h}, width=${w}, top= ${top},left= ${left}, location=no, toolbar=no, menubar=no, scrollbars=no, resizable=no, status=no`)
}

const addShadowStyle = (elem, css = '') => {
  const shadow = elem.shadowRoot
  const style = document.createElement('style')
  style.type = 'text/css'
  style.innerHTML = css
  appendChild(style, shadow)
}

export {
  createDefaultCodeTree,
  getStorageCodeTreeByCodeIdKey,
  appendChild,
  findCodeOfName,
  addPrefixOfLess,
  processLibraries,
  openWindow,
  addShadowStyle,
}
