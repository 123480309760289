import React, { useContext } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import { Button } from "src/components/Button";

import { useHistory } from "react-router-dom";
import { Context } from "src/context/GlobalContext";
import { ToggleSwitch } from "src/components/ToggleSwitch";
import {isCNHost} from "src/utils/isCNHost";
import styles from "./SidebarItem.module.scss";

const SidebarItem = React.memo(
  ({
    indexItem,
    iconSrc,
    title,
    altImage,
    description,
    actionButton,
    githubLink,
    docsLink,
    docInfo,
    setIsActive,
    isActive,
    to,
  }) => {
    const history = useHistory();
    const [state, dispatch] = useContext(Context);
    const { t } = useTranslation('translation',{ keyPrefix: 'sidebar'});

    const handleChangeSwitch = (value) => {
      dispatch({ type: "TOGGLE_SHOW_COLLABORATION", payload: value });
    };

    return (
      <div
        onClick={(event) => {
          event.stopPropagation();
          history.push(to);
          if (indexItem === isActive) {
            setIsActive(null);
            return;
          }
          dispatch({ type: "SET_MENU_TITLE", payload: title });
          if(state.screenSize === 'mobile') {
            dispatch({ type: "TOGGLE_SIDEBAR", payload: false });
          }
          setIsActive(indexItem);
        }}
        className={cx(styles.item, {
          [styles.activeItem]:
            indexItem === isActive || to === history.location.pathname,
        })}
      >
        <div className={styles.itemHeader}>
          <img className={styles.itemImg} src={iconSrc} alt={t(altImage)} />
          <span className={styles.title}>{t(title)}</span>
        </div>
        <p className={styles.subtitle}>{t(description)}</p>
        <div style={{display:isCNHost?'none':'block'}}>
          <div>
            {to === history.location.pathname && docInfo.title && docInfo.link && (
              <p
                onClick={(event) => {
                  event.stopPropagation();
                  const win = window.open(docInfo.link, "_blank");
                  win.focus();
                }}
                className={styles.link}
              >
                {t(docInfo.title)}
              </p>
            )}
            {to === history.location.pathname && actionButton && (
              <>
                {to === "/collaboration_" && (
                  <div
                    onClick={(event) => event.stopPropagation()}
                    className={styles.toggleBox}
                  >
                    <ToggleSwitch
                      id="turnCollaboration"
                      checked={state.showCollaboration}
                      onChange={handleChangeSwitch}
                    />
                    <span className={styles.label}>
                      {t('Show side-by-side collaboration')}
                    </span>
                  </div>
                )}
                <div className={styles.service}>
                  {githubLink && (
                    <a href = {githubLink}>
                      <Button 
                        handleClick={(event) => {
                          event.stopPropagation();
                        }}
                        isArrow
                        title="Github"
                        variant="outline"
                      />
                    </a>
                  )}
                    {docsLink && (
                      <a href = {docsLink} className={to === "/collaboration"?styles.flex1:""}>
                        <Button
                          handleClick={(event) => {
                            event.stopPropagation();
                          }}
                          isArrow
                          title={t("Docs")}
                          variant="outline"
                        />
                      </a>
                    )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
);

SidebarItem.defaultProps = {
  indexItem: null,
  isActive: null,
};

SidebarItem.propTypes = {
  indexItem: PropTypes.number,
  isActive: PropTypes.number,
  iconSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  altImage: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  docsLink: PropTypes.string.isRequired,
  githubLink: PropTypes.string.isRequired,
  actionButton: PropTypes.bool.isRequired,
  docInfo: PropTypes.shape({
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  setIsActive: PropTypes.func.isRequired,
  to: PropTypes.string.isRequired,
};

export { SidebarItem };
