import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./Button.module.scss";

const Button = React.memo(
  ({ type, title, variant, isArrow, handleClick, isCenterTitle }) => {
    return (
      <button
        onClick={handleClick}
        className={classnames(styles.button, {
          [styles[variant]]: variant,
          [styles.arrow]: isArrow,
          [styles.titleCenter]: isCenterTitle,
        })}
        type={type}
      >
        <div>{title}</div>
      </button>
    );
  }
);

Button.defaultProps = {
  type: "button",
  title: "",
  variant: "containedOrange",
  isArrow: false,
  handleClick: () => {},
  isCenterTitle: false,
};

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  title: PropTypes.string,
  variant: PropTypes.oneOf(["outline", "containedOrange", "containedWhite"]),
  isArrow: PropTypes.bool,
  isCenterTitle: PropTypes.bool,
  handleClick: PropTypes.func,
};

export { Button };
