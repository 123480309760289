import React, { useState, useContext, useEffect, useCallback } from "react";
import cx from "classnames";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Context } from "src/context/GlobalContext";
import { computeAIChatBaseURL } from 'src/utils/computeAIBaseURL'
import logo from "src/assets/svg/logo-foxid.svg";
import navigation from "src/assets/svg/navigation.svg";
import navigationActive from "src/assets/svg/navigation-active.svg";
import { isCNHost } from "src/utils/isCNHost";
import { ToggleSwitch } from "src/components/ToggleSwitch";
import styles from "./Header.module.scss";
import { Button } from "../Button";
import { SelectMenu } from "./components/SelectMenu";
import { Select } from "../Select";
import { Dropdown } from "../Dropdown"

const TURN_ON_OFF_STORE_KEY = '__foxit_websdk_turn_on_off_tooltip__';

function parseBoolean(value) {
  if(!value) {
    return false;
  }
  switch(typeof value) {
    case 'string':
      switch(value.toLowerCase()) {
        case 'false':
          return false;
        default:
          return true;
      }
    case 'number':
      return value !== 0;
    default:
      return !!value;
  }
}

const aiChatURL = `${computeAIChatBaseURL()}/ai-assistant/?ref=websdk_demo`

const Header = React.memo(() => {
  const [isTurn, setIsTurn] = useState(parseBoolean(localStorage.getItem(TURN_ON_OFF_STORE_KEY) || true));
  const location = useLocation();
  const [isOpenViewMode, setIsOpenViewMode] = useState(false);
  const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false);
  const [state] = useContext(Context);
  const { t, i18n } = useTranslation('translation',{ keyPrefix: 'header'});

  const postTurnOnMessage = useCallback(() => {
    const cwin = state.refIframe.current?.contentWindow;
    if(!cwin) {
      return;
    }
    const isTurnValue = state.screenSize === "desktop" ? isTurn : false
    cwin.postMessage(
      JSON.stringify({ isTurn:isTurnValue, language: i18n.language }),
      "*"
    );
  }, [state.refIframe, state.screenSize, isTurn]);

  const handleChangeSwitch = (value) => {
    setIsTurn(value);
  };

  const closeBurgerMenu = () => {
    setIsOpenBurgerMenu(false);
  };

  useEffect(() => {
    const onIframeReady = (event) => {
      if(event.data === 'ready') {
        postTurnOnMessage();
      }
    };
    window.addEventListener('message', onIframeReady);
    return () => {
      window.removeEventListener('message', onIframeReady);
    }
  }, [state.isLoadingIframe, state.refIframe.current, isTurn])

  useEffect(() => {
    postTurnOnMessage();
  }, [isTurn, state.isLoadingIframe, state.screenSize]);

  useEffect(() => {
      localStorage.setItem(TURN_ON_OFF_STORE_KEY, isTurn);
  }, [isTurn])

  return (
    <>
      <header className={styles.header}>
        <div className={styles.navigation}>
          <div className={styles.blockImg}>
            <a href = "/#">
              <img className={styles.logo} src={logo} alt="foxit logo" />
            </a>
          </div>
          <p
            className={cx(styles.item, {
              [styles.visible]: state.screenSize === 'mobile',
            })}
          >
            {t('web SDK demo')}
          </p>
          {state.screenSize === 'desktop' && (
            <div
              className={cx(styles.toggleBox, {
                [styles.visible]: state.screenSize !== 'desktop',
              })}
            >
              <ToggleSwitch
                id="turn"
                checked={isTurn}
                onChange={handleChangeSwitch}
              />
              <label className={styles.label}>
                {isTurn ? t('Tour On') : t('Tour Off')}
              </label>
            </div>
          )}
          {state.screenSize === 'desktop' &&
            location.pathname !== '/collaboration' && (
              <Select setIsOpen={setIsOpenViewMode} isOpen={isOpenViewMode}/>
            )}
        </div>
        {state.screenSize !== 'desktop' && (
          <>
            <SelectMenu setIsOpenBurgerMenu={setIsOpenBurgerMenu}/>
            <Select setIsOpen={setIsOpenViewMode} isOpen={isOpenViewMode}/>
          </>
        )}
        <div className={styles.service}>
          {state.screenSize === 'desktop' && !isCNHost && (
            <div className={styles.item}>
              <a
                href="https://developers.foxit.com/contact/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=clickquote"
                target="about:blank"> {t('Get a quote')}</a>
            </div>
          )}

          {state.screenSize === 'mobile' && (
            <button
              onClick={() => {
                setIsOpenBurgerMenu(!isOpenBurgerMenu)
              }}
              className={styles.menu}
            >
              <img
                className={styles.img}
                src={isOpenBurgerMenu ? navigationActive : navigation}
                alt="navigation"
              />
            </button>
          )}
          {state.screenSize === 'desktop' && (
            <>
              {!isCNHost && <div style={{
                marginRight: 10
              }}>
                <a className={styles.aiLinkBtn}
                   href={aiChatURL}
                   rel="noopener noreferrer"
                   target="about:blank">
                  {t("Coding with AI")}
                </a>
              </div>}
              {!isCNHost &&<div className={styles.btn}>
                <a
                  href="https://developers.foxit.com/pdf-sdk/free-trial/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=clickfreetrial"
                  target="about:blank">
                  <Button
                    title={t("Free Trial")}
                    type="button"
                    variant="containedOrange"
                  />
                </a>
              </div>}
              <Dropdown />
            </>
          )}
          {state.screenSize === 'tablet' && (
            <>
              {!isCNHost && <div className={styles.btn}>
                <a
                  href="https://developers.foxit.com/pdf-sdk/free-trial/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=clickfreetrial"
                  target="about:blank">
                  <Button
                    title={t("Free Trial")}
                    type="button"
                    variant="containedOrange"
                  />
                </a>
              </div>}
              <Dropdown />
            </>
          )}
        </div>
      </header>
      {state.screenSize === 'mobile' && isOpenBurgerMenu && (
        <>
          <div className={styles.burgerContainer}>
            <ul className={styles.burgerList}>
              {!isCNHost && <li onClick={closeBurgerMenu} className={styles.burgerItem}>
                <a className={styles.quote}
                   href="https://developers.foxit.com/contact/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=clickquote"
                   target="about:blank"> {t('Get a quote')}</a>
              </li>}
              {!isCNHost && <li onClick={closeBurgerMenu} className={styles.burgerItem}>
                <a
                  href="https://developers.foxit.com/pdf-sdk/free-trial/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=clickfreetrial"
                  target="about:blank">
                  <Button
                    title={t("Free Trial")}
                    type="button"
                    variant="containedOrange"
                  />
                </a>
              </li>}
              {
                false && !isCNHost &&
                <li onClick={closeBurgerMenu} className={styles.burgerItem}>
                  <a className={styles.aiLinkBtn}
                     href={aiChatURL}
                     rel="noopener noreferrer"
                     target="about:blank">
                    {t("Coding with AI")}
                  </a>
                </li>
              }
              <Dropdown />
            </ul>
          </div>
          <div className={styles.background}/>
        </>
      )}
    </>
  );
});

export { Header };
