import React, { useContext, useState } from "react";
import cx from "classnames";
import { useTranslation } from "react-i18next";

import defaultSetup from "src/assets/svg/default-setup.svg";
import { Context } from "src/context/GlobalContext";
import {isCNHost} from "src/utils/isCNHost";
import { sidebarConfig } from "./config";
import { SidebarItem } from "./components/SidebarItem";

import styles from "./Sidebar.module.scss";
import { Button } from "../Button";

const Sidebar = React.memo(() => {
  const [isActive, setIsActive] = useState(null);
  const [state, dispatch] = useContext(Context);
  const { t } = useTranslation('translation',{ keyPrefix: 'sidebar'});
  
  return (
    <>
      <div
        className={cx({
          [styles.isOpen]: state.isOpenSidebar,
          [styles.container]: state.screenSize === 'desktop',
          [styles.containerTablet]: state.screenSize === 'tablet',
          [styles.containerMobile]: state.screenSize === 'mobile'
        })}
      >
        <div className={styles.info}>
          <h2 className={styles.title}>{t('Welcome to the Foxit Web Demo')}</h2>
          <p className={styles.subtitle}>
            {t('Welcome Subtitle')}
          </p>
          <p className={styles.subtitle}>
            <b>{t('See for yourself')}</b> — {t('explore configurations & features')}:
          </p>
        </div>
        <div className={styles.listItem}>
          {state.screenSize === 'desktop' &&
            sidebarConfig.map(
              (
                {
                  iconSrc,
                  title,
                  altImage,
                  description,
                  actionButton,
                  docInfo,
                  to,
                  githubLink,
                  docsLink,
                },
                index
              ) => {
                return (
                  <SidebarItem
                    key={`${title}_${description}`}
                    iconSrc={iconSrc}
                    title={title}
                    altImage={altImage}
                    description={description}
                    actionButton={actionButton}
                    docInfo={docInfo}
                    indexItem={index}
                    setIsActive={setIsActive}
                    githubLink={githubLink}
                    docsLink={docsLink}
                    isActive={isActive}
                    to={to}
                  />
                );
              }
            )}
          {state.screenSize !== 'desktop' &&
            sidebarConfig.map(
              (
                {
                  iconSrc,
                  title,
                  altImage,
                  description,
                  actionButton,
                  docInfo,
                  to,
                  docsLink,
                  githubLink
                },
                index
              ) => {
                return (
                  !["Forms", "Collaboration", "Advanced forms", "Measurement"].includes(
                    title
                  ) && (
                    <SidebarItem
                      key={`${title}_${description}`}
                      iconSrc={iconSrc}
                      title={title}
                      altImage={altImage}
                      description={description}
                      actionButton={actionButton}
                      docInfo={docInfo}
                      indexItem={index}
                      setIsActive={setIsActive}
                      githubLink={githubLink}
                      docsLink={docsLink}
                      isActive={isActive}
                      to={to}
                    />
                  )
                );
              }
            )}
          <div className={styles.item}>
            <div className={styles.itemHeader}>
              <img
                className={styles.itemImg}
                src={defaultSetup}
                alt={t('Custom setup')}
              />
              <span className={styles.title}>{t('Custom setup')}</span>
            </div>
            <p className={styles.subtitle}>
              {t('Custom setup description')}
            </p>
            <div className={styles.btn}>
              <a className={styles.btnLink} 
                href = {isCNHost ? 'https://developers.foxitsoftware.cn/free-trial/' : 'https://developers.foxit.com/pdf-sdk/free-trial/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=clickfreetrial2'}>
                <Button isCenterTitle title={t(isCNHost? "Trial download" : "Download Free trial")} />
              </a>
            </div>
            <div className={styles.btn} style={{display:isCNHost?'none':'block'}}>
              <a className={styles.btnLink} href = 'https://developers.foxit.com/contact/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=clickquote2'>
                <Button isCenterTitle title={t("Talk to a specialist")} />
              </a>
            </div>
          </div>
        </div>
      </div>
      {state.screenSize === 'mobile' && state.isOpenSidebar && (
        <div
          onClick={() => dispatch({ type: "TOGGLE_SIDEBAR", payload: false })}
          className={styles.background}
        />
      )}
    </>
  );
});

export { Sidebar };
