import React, { useContext, useMemo } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { Context } from "src/context/GlobalContext";
import { capitalize } from "src/utils/capitalize";
import eye from "src/assets/svg/eye.svg";
import eyeActive from "src/assets/svg/eye-active.svg";
import styles from "./Select.module.scss";

const Select = React.memo(({ isOpen, setIsOpen }) => {
  const [state, dispatch] = useContext(Context);
  const { t } = useTranslation("translation", { keyPrefix: "header" });
  const listItem = useMemo(() => {
    return [
      {
        type: "button",
        title: "Desktop",
        dataAttribute: "desktop",
      },
      {
        type: "button",
        title: "Tablet",
        dataAttribute: "tablet",
      },
      {
        type: "button",
        title: "Mobile",
        dataAttribute: "mobile",
      },
    ];
  }, []);

  const handleChange = (event) => {
    dispatch({
      type: "SET_SCREEN_SIZE",
      payload: event.target.dataset.screenSize,
    });
    setIsOpen(false);
  };

  return (
    <div className={styles.section}>
      {state.screenSize === 'mobile' ? (
        <div onClick={() => setIsOpen(!isOpen)} className={styles.imgBox}>
          <img className = {styles.iconSelect} alt="eye" src={isOpen ? eyeActive : eye} />
        </div>
      ) : (
        <div
          onClick={() => setIsOpen(!isOpen)}
          className={cx(styles.container, {
            [styles.containerActive]: isOpen,
          })}
        >
          <div className={styles.imgBox}>
            <img alt="eye" src={eye} />
          </div>
          <p className={styles.placeholder}>{t(capitalize(state.screenSize))}</p>
        </div>
      )}
      {isOpen && (
        <div className={styles.boxOpen}>
          <p className={styles.title}>{t('View mode')}</p>
          {listItem.map(({ type, dataAttribute, title }) => {
            return (
              <button
                key={`${type}_${dataAttribute}`}
                className={cx(styles.item, {
                  [styles.itemActive]: dataAttribute === state.screenSize,
                })}
                onClick={handleChange}
                data-screen-size={dataAttribute}
                type={type}
              >
                {t(title)}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
});

Select.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export { Select };
