import React from 'react'
import { HomePage } from 'src/scenes/HomePage'
import { StoreGlobal } from 'src/context/GlobalContext'
import { Route, Switch } from 'react-router-dom'
import { Playground } from './components/Playground'

const App = () => {
  return (
    <StoreGlobal>
      <Switch>
        <Route path="/playground">
          <Playground/>
        </Route>
        <Route path="/">
          <HomePage/>
        </Route>
      </Switch>
    </StoreGlobal>
  )
}

export { App }
