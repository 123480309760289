import React, { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import { isMobile, isTablet } from "src/utils/device";

const getOrientation = () => {
  if(!window.screen.orientation) {
    if(window.matchMedia) {
      const result = window.matchMedia("(orientation: portrait)");
      if(result.matches) {
        return 'portrait';
      }
      return 'landscape';
    }
    if(window.innerWidth < window.innerHeight) {
      return 'portrait';
    }
    return 'landscape';
  }
  switch(window.screen.orientation.type) {
    case 'landscape-primary':
    case 'landscape-secondary':
    return 'landscape';
    default:
    return 'portrait';
  }
}
const Reducer = (state, action) => {
  switch (action.type) {
    case "SET_MENU_TITLE":
      return {
        ...state,
        menuTitle: action.payload,
      };
    case "TOGGLE_SIDEBAR":
      return {
        ...state,
        isOpenSidebar: action.payload,
      };
    case "SET_SCREEN_SIZE":
      return {
        ...state,
        screenSize: action.payload,
      };
    case "TOGGLE_SHOW_COLLABORATION":
      return {
        ...state,
        showCollaboration: action.payload,
      };
    case "SET_REF_IFRAME":
      // global variable used by playground
      window.exampleOuterFrame = action.payload?.current
      return {
        ...state,
        refIframe: action.payload,
      };

    case "SET_REF_PLAYGROUND_IFRAME":
      return {
        ...state,
        refPlaygroundIframe: action.payload,
      };

    case "SET_IS_LOADING":
      return {
        ...state,
        isLoadingIframe: action.payload,
      };

    case "TOGGLE_PLAYGROUND":
      return {
        ...state,
        playgroundOpen: action.payload
      }
    case 'UPDATE_ORIENTATION':
      return {
        ...state,
        orientation: action.payload
      }
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
};

const initialState = {
  menuTitle: "Select feature",
  isOpenSidebar: false,
  screenSize: (() => {
    if(isMobile) {
      return 'mobile';
    }
    if(isTablet) {
      return 'tablet'
    }
    return 'desktop'
  })(),
  showCollaboration: false,
  refIframe: false,
  refPlaygroundIframe: null,
  isLoadingIframe: false,
  orientation: getOrientation(),
  playgroundOpen: false,
  language: 'en-US',
};

const StoreGlobal = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  useEffect(() => {
    const listener = () => {
      dispatch({
        type: 'UPDATE_ORIENTATION',
        payload: getOrientation()
      })
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  })
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

StoreGlobal.propTypes = {
  children: PropTypes.node.isRequired,
};

const Context = createContext(initialState);
export { StoreGlobal, Context };
