import { envConfig } from "./env.config";

const { origin } = window.location;

export const isCNHost = (() => {
  // 开发环境默认中国区
  if (process.env.NODE_ENV === "development") {
    return true;
  }
  // 根据envConfig配置的host判断是否是中国区
  return Object.values(envConfig.CN).includes(origin);
})();
