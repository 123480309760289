import { createValidation } from "./validateForm";

const url = (value) => {
  const regexUrl = new RegExp(
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
  );

  const regexUrlDomain = new RegExp(/webviewer-examples/);

  if (!regexUrl.test(value) && value) {
    return false;
  }
  if( process.env.NODE_ENV !== 'development'){
    if (!regexUrlDomain.test(value) && value) {
      return false;
    }
  }

  return true;
};

export const checkValidationUrl = createValidation(
  url,
  "Couldn’t load URL, please try another."
);

const USER_AGENT = navigator.userAgent.toLowerCase();

export const isIOS = /ipad|iphone|ipod/.test(USER_AGENT);
export const isIPHONE = /iphone/.test(USER_AGENT);
export const isIPAD = /ipad/.test(USER_AGENT);
export const isIPOD = /ipod/.test(USER_AGENT);
export const isAndroid = /android/.test(USER_AGENT);
export const isBlackBerry = /blackberry/.test(USER_AGENT);
export const isWebOS = /webos/.test(USER_AGENT);
export const isKindle = /silk|kindle/.test(USER_AGENT);
export const isAliAppBrowser = /aliapp/.test(USER_AGENT);
export const isTablet = !isAliAppBrowser && /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(USER_AGENT);
export const isMobile = !isTablet && (/mobile/.test(USER_AGENT) || isIOS || isAndroid || isBlackBerry || isWebOS || isKindle);
export const isDesktop = !isMobile && !isTablet && !isAndroid && !isIPHONE && !isIPAD && !isIPOD && !isKindle && !isWebOS;
