import { Button } from 'antd'
import { useCallback, useEffect } from 'react'
import { useTranslation } from "react-i18next";
// import { useHistory } from 'react-router-dom'
import { ReloadOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { addPrefixOfLess, appendChild, findCodeOfName, getStorageCodeTreeByCodeIdKey } from '../utils'
import { CODE_ID_PREFIX, RUNNER_CODE_ID_KEY } from '../config'
import defaultCode from '../default-code'
import './Runner.scss'

function appendScript ({
  type = 'text',
  src,
  scriptStr
}) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.onload = () => {
      resolve(script)
    }
    script.onerror = () => reject(new Error(`Script load error for: ${type === 'text' ? 'Script Tab' : src}`))
    if (type === 'text') {
      script.appendChild(document.createTextNode(scriptStr))
    } else if (type === 'src') {
      script.src = src
    }
    appendChild(script, document.getElementById('iframe-script'))
  })
}

function appendHtml (node) {
  appendChild(node, document.getElementById('iframe-html'))
}

function appendStyle (cssStr) {
  const css = addPrefixOfLess(cssStr)
  const style = document.createElement('style')
  style.type = 'text/css'
  style.innerHTML = css
  appendChild(style, document.getElementById('iframe-style'))
}

export const Runner = () => {
  const { t } = useTranslation('translation',{ keyPrefix: 'Playground'});
  useEffect(async () => {
    const runnerCodeId = localStorage.getItem(RUNNER_CODE_ID_KEY)
    const codeIdKey = `${CODE_ID_PREFIX}#${runnerCodeId}`
    const localStorageCodeTree = getStorageCodeTreeByCodeIdKey(codeIdKey)
    const html = findCodeOfName('html', localStorageCodeTree?.files)?.content || null
    await appendHtml(html)
    const script = findCodeOfName('javascript', localStorageCodeTree?.files)?.content || null
    appendScript({
      scriptStr: defaultCode.runtime
    })
    appendScript({ scriptStr: script })
    const css = findCodeOfName('css', localStorageCodeTree?.files)?.content || null
    appendStyle(css)
  }, [])

  const refresh = useCallback(
    () => {
      // reload pdfviewer demo iframe, when the iframe is loaded,
      // it will reload the iframe of the playground
      // (refer to `foxit-pdf-sdk-web-demo-frontend/src/scenes/HomePage/components/Iframe/Iframe.js#68`)
      window.parent.exampleOuterFrame.contentWindow.location.reload()
    }
    , [])

  const goBack = useCallback(
    () => {
      const href = `${window.location.origin}/playground`
      window.location.href = href
    }, [])

  return <div className="runner">
    <div className="actions">
      <Button type="text" icon={<ArrowLeftOutlined/>} onClick={goBack}/>
      <Button type="text" icon={<ReloadOutlined/>} onClick={refresh}>{t("Refresh")}</Button>
    </div>
    <div className="runner-content">
      <div id="iframe-html"/>
      <div id="iframe-script"/>
      <div id="iframe-style"/>
    </div>
  </div>
}
