import defaultSetup from "src/assets/svg/default-setup.svg";
import advancedForms from "src/assets/svg/advanced-forms.svg";
import editPdf from "src/assets/svg/edit-pdf.svg";
import annotation from "src/assets/svg/annotation.svg";
import measurement from "src/assets/svg/measurement.svg";
import redaction from "src/assets/svg/redaction.svg";
import forms from "src/assets/svg/forms.svg";
import digitalSignature from "src/assets/svg/digital-signature.svg";
import collaboration from "src/assets/svg/collaboration.svg";
import search from "src/assets/svg/search.svg";
import conversion from "src/assets/svg/conversion.svg";
import comparison from "src/assets/svg/comparison.svg";
import multipleInstances from "src/assets/svg/multiple-instances.svg";

const gitExampleLink = "https://github.com/foxitsoftware/foxit-pdf-sdk-web-demo/tree/main/examples/"
const sidebarConfig = [
  {
    title: "Default setup",
    description: "Default setup description",
    iconSrc: defaultSetup,
    actionButton: false,
    docInfo: {
      link: "",
      title: "",
    },
    githubLink: "",
    docsLink: "",
    to: "/",
    altImage: "Default setup",
  },
  {
    title: "Edit PDFs",
    description: "Edit PDFs description",
    iconSrc: editPdf,
    actionButton: true,
    docInfo: {
      link: "",
      title: "",
    },
    githubLink: `${gitExampleLink}edit_pdfs`,
    docsLink: "https://developers.foxit.com/developer-hub/document/pdf-sdk-web-edit-pdfs/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=editpdfsarticle",
    to: "/edit_pdfs",
    altImage: "Edit PDFs",
  },
  {
    title: "Annotation",
    description: "Annotation description",
    iconSrc: annotation,
    actionButton: true,
    docInfo: {
      link: "https://developers.foxit.com/features/annotations/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=annotations",
      title: "Read more about Annotation",
    },
    githubLink: `${gitExampleLink}annotation`,
    docsLink: "https://developers.foxit.com/developer-hub/document/pdf-sdk-web-annotations/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=annotationarticle",
    to: "/annotation",
    altImage: "Annotation",
  },
  {
    title: "Measurement",
    description: "Measurement description",
    iconSrc: measurement,
    actionButton: true,
    docInfo: {
      link: "",
      title: "",
    },
    githubLink: `${gitExampleLink}measurement`,
    docsLink: "https://developers.foxit.com/developer-hub/document/pdf-sdk-web-measurement/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=measurementarticle",
    to: "/measurement",
    altImage: "Measurement",
  },
  {
    title: "Redaction",
    description: "Redaction description",
    iconSrc: redaction,
    actionButton: true,
    docInfo: {
      link: "https://developers.foxit.com/add-ons/redaction/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=redaction",
      title: "Read more about Redaction",
    },
    githubLink: `${gitExampleLink}redaction`,
    docsLink: "https://developers.foxit.com/developer-hub/document/pdf-sdk-web-redaction/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=redactionarticle",
    to: "/redaction",
    altImage: "Redaction",
  },
  {
    title: "Forms",
    description: "Forms description",
    iconSrc: forms,
    actionButton: true,
    docInfo: {
      link: "https://developers.foxit.com/features/smart-pdf-forms/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=basicforms",
      title: "Read more about Forms",
    },
    githubLink: `${gitExampleLink}forms`,
    docsLink: "https://developers.foxit.com/developer-hub/document/pdf-sdk-web-basic-forms/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=basicformsarticle",
    to: "/forms",
    altImage: "Forms",
  },
  {
    title: "Advanced forms",
    description: "Advanced forms description",
    iconSrc: advancedForms,
    actionButton: true,
    docInfo: {
      link: "https://developers.foxit.com/add-ons/xfa-forms/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=advancedforms",
      title: "Read more about Forms",
    },
    githubLink: `${gitExampleLink}advanced_form`,
    docsLink: "https://developers.foxit.com/developer-hub/document/pdf-sdk-web-advanced-forms/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=advancedformsarticle",
    to: "/advanced_form",
    altImage: "Advanced forms",
  },
  {
    title: "Digital signature",
    description: "Digital signature description",
    iconSrc: digitalSignature,
    actionButton: true,
    docInfo: {
      link: "https://developers.foxit.com/features/digital-signatures/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=digitalsignatures",
      title: "Read more about Digital Signature",
    },
    githubLink: `${gitExampleLink}digital_signature`,
    docsLink: "https://developers.foxit.com/developer-hub/document/pdf-sdk-web-digital-signatures/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=digitalsignaturesarticle",
    to: "/digital_signature",
    altImage: "Digital signature",
  },
  {
    title: "Collaboration",
    description: "Collaboration description",
    iconSrc: collaboration,
    actionButton: true,
    docInfo: {
      link: "",
      title: "",
    },
    githubLink: "",
    docsLink: "https://webviewer-demo.foxit.com/docs/collab-developer-guide/",
    to: "/collaboration",
    altImage: "Collaboration",
  },
  {
    title: "Search",
    description: "Search description",
    iconSrc: search,
    actionButton: true,
    docInfo: {
      link: "",
      title: "",
    },
    githubLink: `${gitExampleLink}search`,
    docsLink: "https://developers.foxit.com/developer-hub/document/pdf-sdk-web-search/?utm_source=webviewerdemo&utm_medium=referral&utm_campaign=searcharticle",
    to: "/search",
    altImage: "Search",
  },
  {
    title: "Overlay Comparison",
    description: "Overlay Comparison description",
    iconSrc: comparison,
    actionButton: true,
    docInfo: {
      link: "",
      title: "",
    },
    githubLink: `${gitExampleLink}overlay-comparison`,
    docsLink: "",
    to: "/overlay-comparison",
    altImage: "Overlay Comparison",
  },
  {
    title: "Multiple Instances",
    description: "Multiple Instances description",
    iconSrc: multipleInstances,
    actionButton: true,
    docInfo: {
      link: "",
      title: "",
    },
    githubLink: `${gitExampleLink}multi-instance`,
    docsLink: "",
    to: "/multi-instance",
    altImage: "Multiple Instances",
  },
  {
    title: "Convert PDF to Office",
    description: "Convert PDF to Office description",
    iconSrc: conversion,
    actionButton: true,
    docInfo: {
      link: "",
      title: "",
    },
    githubLink: `${gitExampleLink}conversion`,
    docsLink: "https://developers.foxit.com/developer-hub/document/convert-pdf-word-powerpoint-excel-using-node-js/",
    to: "/conversion",
    altImage: "Conversion",
  },
];

export { sidebarConfig };