import React from 'react'
import PropTypes from 'prop-types'
import Pane from './Pane'
import './SplitPane.scss'

function clearSelection () {
  if (document.body.createTextRange) {
    // https://github.com/zesik/react-splitter-layout/issues/16
    // https://stackoverflow.com/questions/22914075/#37580789
    const range = document.body.createTextRange()
    range.collapse()
    range.select()
  } else if (window.getSelection) {
    if (window.getSelection().empty) {
      window.getSelection().empty()
    } else if (window.getSelection().removeAllRanges) {
      window.getSelection().removeAllRanges()
    }
  } else if (document.selection) {
    document.selection.empty()
  }
}

const DEFAULT_SPLITTER_SIZE = 4

class SplitterLayout extends React.Component {
  constructor (props) {
    super(props)
    this.handleResize = this.handleResize.bind(this)
    this.handleMouseMove = this.handleMouseMove.bind(this)
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.handleTouchMove = this.handleTouchMove.bind(this)
    this.handleSplitterMouseDown = this.handleSplitterMouseDown.bind(this)
    this.state = {
      secondaryPaneSize: 0,
      resizing: false
    }
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize)
    document.addEventListener('mouseup', this.handleMouseUp)
    document.addEventListener('mousemove', this.handleMouseMove)
    document.addEventListener('touchend', this.handleMouseUp)
    document.addEventListener('touchmove', this.handleTouchMove)

    const { secondaryInitialSize } = this.props

    let secondaryPaneSize
    if (typeof secondaryInitialSize !== 'undefined') {
      secondaryPaneSize = secondaryInitialSize
    } else {
      const containerRect = this.container.getBoundingClientRect()
      let splitterRect
      if (this.splitter) {
        splitterRect = this.splitter.getBoundingClientRect()
      } else {
        // Simulate a splitter
        splitterRect = { width: DEFAULT_SPLITTER_SIZE, height: DEFAULT_SPLITTER_SIZE }
      }
      secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
        left: containerRect.left + ((containerRect.width - splitterRect.width) / 2),
        top: containerRect.top + ((containerRect.height - splitterRect.height) / 2)
      }, false)
    }
    this.setState({ secondaryPaneSize })
  }

  componentDidUpdate (prevProps, prevState) {
    const { secondaryPaneSize, resizing } = this.state
    const { onSecondaryPaneSizeChange, onDragStart, onDragEnd } = this.props
    if (prevState.secondaryPaneSize !== secondaryPaneSize && onSecondaryPaneSizeChange) {
      onSecondaryPaneSizeChange(secondaryPaneSize)
    }
    if (prevState.resizing !== resizing) {
      if (resizing) {
        if (onDragStart) {
          onDragStart()
        }
      } else if (onDragEnd) {
        onDragEnd()
      }
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('mouseup', this.handleMouseUp)
    document.removeEventListener('mousemove', this.handleMouseMove)
    document.removeEventListener('touchend', this.handleMouseUp)
    document.removeEventListener('touchmove', this.handleTouchMove)
  }

  handleResize () {
    const { percentage } = this.props
    if (this.splitter && !percentage) {
      const containerRect = this.container.getBoundingClientRect()
      const splitterRect = this.splitter.getBoundingClientRect()
      const secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
        left: splitterRect.left,
        top: splitterRect.top
      }, false)
      this.setState({ secondaryPaneSize })
    }
  }

  handleMouseMove (e) {
    const { resizing } = this.state
    if (resizing) {
      const containerRect = this.container.getBoundingClientRect()
      const splitterRect = this.splitter.getBoundingClientRect()
      const secondaryPaneSize = this.getSecondaryPaneSize(containerRect, splitterRect, {
        left: e.clientX,
        top: e.clientY
      }, true)
      clearSelection()
      this.setState({ secondaryPaneSize })
    }
  }

  handleTouchMove (e) {
    this.handleMouseMove(e.changedTouches[0])
  }

  handleSplitterMouseDown () {
    clearSelection()
    this.setState({ resizing: true })
  }

  handleMouseUp () {
    this.setState(prevState => (prevState.resizing ? { resizing: false } : null))
  }

  getSecondaryPaneSize (containerRect, splitterRect, clientPosition, offsetMouse) {
    let totalSize
    let splitterSize
    let offset
    const { vertical, primaryIndex, percentage, primaryMinSize, secondaryMinSize = 0 } = this.props
    if (vertical) {
      totalSize = containerRect.height
      splitterSize = splitterRect.height
      offset = clientPosition.top - containerRect.top
    } else {
      totalSize = containerRect.width
      splitterSize = splitterRect.width
      offset = clientPosition.left - containerRect.left
    }
    if (offsetMouse) {
      offset -= splitterSize / 2
    }
    if (offset < 0) {
      offset = 0
    } else if (offset > totalSize - splitterSize) {
      offset = totalSize - splitterSize
    }

    let secondaryPaneSize
    if (primaryIndex === 1) {
      secondaryPaneSize = offset
    } else {
      secondaryPaneSize = totalSize - splitterSize - offset
    }
    let primaryPaneSize = totalSize - splitterSize - secondaryPaneSize
    if (percentage) {
      secondaryPaneSize = (secondaryPaneSize * 100) / totalSize
      primaryPaneSize = (primaryPaneSize * 100) / totalSize
      splitterSize = (splitterSize * 100) / totalSize
      totalSize = 100
    }

    if (primaryPaneSize < primaryMinSize) {
      secondaryPaneSize = Math.max(secondaryPaneSize - (primaryMinSize - primaryPaneSize), 0)
    } else if (secondaryPaneSize < secondaryMinSize) {
      secondaryPaneSize = Math.min(totalSize - splitterSize - primaryMinSize, secondaryMinSize)
    }

    return secondaryPaneSize
  }

  render () {
    const { customClassName, vertical, primaryIndex, percentage, children } = this.props
    const { resizing, secondaryPaneSize } = this.state
    let containerClasses = 'splitter-layout'
    if (customClassName) {
      containerClasses += ` ${customClassName}`
    }
    if (vertical) {
      containerClasses += ' splitter-layout-vertical'
    }
    if (resizing) {
      containerClasses += ' layout-changing'
    }

    const myChildren = React.Children.toArray(children).slice(0, 2)
    if (myChildren.length === 0) {
      myChildren.push(<div/>)
    }
    const wrappedChildren = []
    const myPrimaryIndex = (primaryIndex !== 0 && primaryIndex !== 1) ? 0 : primaryIndex
    for (let i = 0; i < myChildren.length; ++i) {
      let primary = true
      let size = null
      if (children.length > 1 && i !== myPrimaryIndex) {
        primary = false
        size = secondaryPaneSize
      }
      wrappedChildren.push(
        <Pane vertical={vertical} percentage={percentage} primary={primary} size={size}>
          {myChildren[i]}
        </Pane>
      )
    }

    return (
      <div className={containerClasses} ref={(c) => { this.container = c }}>
        {wrappedChildren[0]}
        {wrappedChildren.length > 1 &&
          (
            <div
              role="separator"
              className="layout-splitter"
              ref={(c) => { this.splitter = c }}
              onMouseDown={this.handleSplitterMouseDown}
              onTouchStart={this.handleSplitterMouseDown}
            />
          )
        }
        {wrappedChildren.length > 1 && wrappedChildren[1]}
      </div>
    )
  }
}

SplitterLayout.propTypes = {
  customClassName: PropTypes.string,
  vertical: PropTypes.bool,
  percentage: PropTypes.bool,
  primaryIndex: PropTypes.number,
  primaryMinSize: PropTypes.number,
  secondaryInitialSize: PropTypes.number,
  secondaryMinSize: PropTypes.number,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  onSecondaryPaneSizeChange: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.node)
}

SplitterLayout.defaultProps = {
  customClassName: '',
  vertical: false,
  percentage: false,
  primaryIndex: 0,
  primaryMinSize: 0,
  secondaryInitialSize: undefined,
  secondaryMinSize: 0,
  onDragStart: null,
  onDragEnd: null,
  onSecondaryPaneSizeChange: null,
  children: []
}

export default SplitterLayout