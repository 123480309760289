import React, { useState, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Context } from "src/context/GlobalContext";
import "./Dropdown.scss";

const Dropdown = React.memo(
  () => {
    const [state, dispatch] = useContext(Context);
    const [isMobile, setIsMobile] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation('translation', { keyPrefix: 'header' });
    const listItem = useMemo(() => {
      return [
        {
          type: "button",
          title: "简体中文",
          dataAttribute: "zh-CN",
        },
        // {
        //   type: "button",
        //   title: "繁体中文",
        //   dataAttribute: "zh-TW",
        // },
        {
          type: "button",
          title: "English",
          dataAttribute: "en-US",
        },
      ];
    }, []);

    useEffect(() => {
      setIsMobile(state.screenSize === 'mobile')
    }, [state.screenSize]);

    const handleMouseEnter = () => {
      if (!isMobile) {
        setIsOpen(true);
      }
    }

    const handleMouseLeave = () => {
      if (!isMobile) {
        setIsOpen(false);
      }
    }

    const handleClick = () => {
      if (isMobile) {
        setIsOpen(!isOpen);
      }
    }

    const handleChange = (language) => {
      localStorage.setItem('lang',language)
      i18n.changeLanguage(language);
      dispatch({
        type: "SET_LANGUAGE",
        language,
      });
      const cwin = state.refIframe.current?.contentWindow;
      if (!cwin) {
        return;
      }
      cwin.postMessage(
        JSON.stringify({ language: i18n.language }),
        "*"
      );
      setIsOpen(false);
    };

    return (
      <div className="dropdown" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick}>
        <button className={`button ${isMobile ? 'isMobile' : ''}`}>
          <div>{t('Language')}</div>
        </button>
        {isOpen && <div className="languageList">
          {listItem.map(({ type, dataAttribute, title }) => {
            return (
              <button
                key={`${type}_${dataAttribute}`}
                onClick={() => handleChange(dataAttribute)}
                type={type}
                className={`item ${i18n.language === dataAttribute ? "itemActive" : ""}`}
              >
                {title}
              </button>
            );
          })}
        </div>}
      </div>
    );
  }
);

export { Dropdown };
