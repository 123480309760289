import React from "react";
import { Route } from "react-router-dom";

import { Layout } from "src/components/Layout";
import { Iframe } from "./components/Iframe";
import pkg from '../../../package.json';

const pkgConfig = pkg.config;

function computeReactAppBaseURL(){
  return pkgConfig[process.env.NODE_ENV];
}

const HomePage = () => {
  const {REACT_APP_BASE_URL} = computeReactAppBaseURL();

  return (
    <Layout>
      <Route
        exact
        path="/"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/hello`} />
          );
        }}
      />
      <Route
        exact
        path="/annotation"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/annotation`} />
          );
        }}
      />
      <Route
        exact
        path="/measurement"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/measurement`} />
          );
        }}
      />
      <Route
        exact
        path="/forms"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/forms`} />
          );
        }}
      />
      <Route
        exact
        path="/redaction"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/redaction`} />
          );
        }}
      />
      <Route
        exact
        path="/edit_pdfs"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/edit_pdfs`} />
          );
        }}
      />
      <Route
        exact
        path="/advanced_form"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/advanced_form`} />
          );
        }}
      />
      <Route
        exact
        path="/digital_signature"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/digital_signature`} />
          );
        }}
      />
      <Route
        exact
        path="/search"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/search`} />
          );
        }}
      />
      <Route
        exact
        path="/collaboration"
        render={() => {
          return (
            <Iframe src={`${REACT_APP_BASE_URL}/#/collaboration`} />
          );
        }}
      />
        <Route
            exact
            path="/conversion"
            render={() => {
                return (
                    <Iframe src={`${REACT_APP_BASE_URL}/#/conversion`} />
                );
            }}
        />
        <Route
            exact
            path="/overlay-comparison"
            render={() => {
                return (
                    <Iframe src={`${REACT_APP_BASE_URL}/#/overlay-comparison`} />
                );
            }}
        />
        <Route
            exact
            path="/multi-instance"
            render={() => {
                return (
                    <Iframe src={`${REACT_APP_BASE_URL}/#/multi-instance`} />
                );
            }}
        />
    </Layout>
  );
};

export { HomePage };
